import React, { Component } from "react";
import "./Manage.css";
import FirebaseContext from "./Firebase-config";
import Data from "./DB";

class AdminPage extends Component {
  constructor() {
    super();
    this.data = new Data();
    this.state = {
      guests: {},
      guestsList: [],
      guestsCodes: [],
      guestsDocIDs: [],
      guestsSongs: [],
      guestsConfirms: [],
      guestsCounter: [],
    };
  }
  async componentDidMount() {
    document.title = "Admin Bereich";
    this.setState({
      guests: await this.data.getData(),
    });
    this.setState({
      guestsList: this.state.guests.names,
      guestsCodes: this.state.guests.codes,
      guestsSongs: this.state.guests.songs,
      guestsCounter: this.state.guests.counts,
      guestsConfirms: this.state.guests.confirms,
      guestsDocIDs: this.state.guests.ids,
    });
  }

  render() {
    let tableInfo = [];
    for (var i = 0; i < this.state.guestsList.length; i++) {
      let temp = (
        <tr>
          <th scope="row">{i + 1}</th>
          <td>{this.state.guestsList[i]}</td>
          <td>{this.state.guestsConfirms[i] ? "ja" : "nein"}</td>
          <td>{this.state.guestsCounter[i]}</td>
          <td>{this.state.guestsSongs[i]}</td>
        </tr>
      );
      tableInfo.push(temp);
    }
    return (
      <div className="Manage">
        <header>Admin Bereich</header>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Anwesend</th>
              <th scope="col">Anzahl</th>
              <th scope="col">Lied</th>
            </tr>
          </thead>
          <tbody>{tableInfo}</tbody>
        </table>
      </div>
    );
  }
}

export default AdminPage;
