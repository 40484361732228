const weddingData = {
  bride: "Antonia",
  groom: "Wladislaw",
  date: "12. Dezember 2020 (P.s.: 12.12.2020 ist ein cooles Datum 😎)",
  time: "12:00 (vielleicht verschieben wir es auf 12:12 🤔😁)",
  time2_optional: "",
  time_hint: "Bitte am besten 20 Minuten vorher da sein.",
  standesamt: "Rathauspl. 13, 56179 Vallendar 📝",
  restaurant: "Schlossstraße 69, 56564 Neuwied 💃🏻🕺🏻",
  detail1_optional: "Unsere Farbe ist: Lila 🟣",
  detail2_optional:
    "Deswegen dürfen Sie gerne einen Accessoires dieser Farbe dabei haben 😋",
  detail3_optional: "Unserer Hashtag für den Tag lautet #taubergerpictures",
};

export default weddingData;
