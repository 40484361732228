import React, { Component } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import { FaArrowDown } from "react-icons/fa";
import { IconContext } from "react-icons";
import icon from "./wedding-ring.svg";
import "./App.css";
import FirebaseContext from "./Firebase-config";
import Main from "./Main";
import Data from "./DB";
import Variables from "./Variables";

class Wedding extends Component {
  constructor() {
    super();
    this.data = new Data();
    this.state = {
      guests: {},
      modalOpen: false,
      confirm: false,
      count: 0,
      name: "",
      song: "",
      guestsList: [],
      guestsCodes: [],
      guestsDocIDs: [],
      validated: false,
      selectedGuestIndex: 0,
    };
  }
  async componentDidMount() {
    document.title = "Wedding";
    this.setState({
      guests: await this.data.getData(),
    });
    this.setState({
      guestsList: this.state.guests.names,
      guestsCodes: this.state.guests.codes,
      guestsDocIDs: this.state.guests.ids,
    });
  }
  openModal = () => this.setState({ modalOpen: true });
  closeModal = () => this.setState({ modalOpen: false });

  checkCode(code) {
    if (
      code.target.value == this.state.guestsCodes[this.state.selectedGuestIndex]
    ) {
      this.setState({
        validated: true,
      });
    } else {
      this.setState({
        validated: false,
      });
    }
  }

  addGast = (e) => {
    if (this.state.validated) {
      e.preventDefault();
      this.data.saveToFirestore(
        this.state.guestsDocIDs[this.state.selectedGuestIndex],
        this.state.confirm,
        this.state.count,
        this.state.guestsList[this.state.selectedGuestIndex],
        this.state.song,
        this.state.guestsCodes[this.state.selectedGuestIndex]
      );
      this.setState({
        count: 0,
        confirm: false,
        song: "",
        validated: false,
      });
      this.openModal();
    }
  };
  handleChange(event) {
    let fieldName = event.target.name;
    let fleldVal = event.target.value;
    this.setState({
      [fieldName]: fleldVal,
    });
  }
  render() {
    let form;
    let subButton;
    let validationPassed;
    let options = [];
    let temp;
    form = (
      <div className={"" + (!this.state.confirm ? "d-none" : "")}>
        <Form.Group controlId="formPersonCount">
          <Form.Label>Anzahl</Form.Label>
          <Form.Control
            type="number"
            name="count"
            placeholder="Anzahl der Gäste"
            onChange={this.handleChange.bind(this)}
          />
        </Form.Group>
        <Form.Group controlId="formSong">
          <Form.Label>Ihren Lieblingssong</Form.Label>
          <p className="text-muted confirmText">
            P.s. es können auch gerne mehrere Songs sein <span>🤪</span>
          </p>
          <Form.Control
            type="text"
            name="song"
            placeholder="Bei diesem Song werde ich 100% tanzen!"
            onChange={this.handleChange.bind(this)}
          />
        </Form.Group>
      </div>
    );
    subButton = (
      <div>
        <Button type="submit" id="submitBtn" className="btn-success button">
          Absenden
        </Button>
      </div>
    );
    if (this.state.validated) {
      validationPassed = (
        <div>
          <Form.Group controlId="confirmSwitch">
            <Form.Text className="confirmText goo">
              Wenn Sie an der Hochzeit teilnehmen werden, bitte hier klicken,
              dann werden weitere Felder eingeblendet.
            </Form.Text>
            <Form.Check
              type="checkbox"
              id="custom-switch"
              className="form-control-lg"
              label="JA, ich/wir komme(n)!"
              checked={this.state.confirm}
              onChange={(s) => {
                this.setState({ confirm: !this.state.confirm });
              }}
            />
          </Form.Group>
          {form}
          {subButton}
          <div className="mb-3" />
        </div>
      );
    }
    for (var i = 0; i < this.state.guestsList.length; i++) {
      temp = (
        <option key={i} value={this.state.guestsList[i]}>
          {this.state.guestsList[i]}
        </option>
      );
      options.push(temp);
    }
    let selector = (
      <Form.Control
        as="select"
        onChange={(s) => {
          this.setState({
            selectedGuestIndex: s.target.selectedIndex - 1,
          });
        }}
      >
        <option value="" disabled selected>
          Bitte auswählen
        </option>
        {options}
      </Form.Control>
    );
    return (
      <div className="App">
        <Main />
        <header className="App-header">
          <Modal show={this.state.modalOpen} onHide={this.closeModal}>
            <Modal.Header closeButton>
              <Modal.Title>Erfolgreich</Modal.Title>
            </Modal.Header>
            <Modal.Body>Vielen Dank für Ihre Rückmeldung!</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.closeModal}>
                Cool! Danke
              </Button>
            </Modal.Footer>
          </Modal>
          <div className="verticalTextContainer">
            <p className="rotateText verticalText">{Variables.groom}</p>
            <p className="verticalText">{Variables.bride}</p>
          </div>
          <img src={icon} className="App-logo" alt="logo" />
          <h2>Herzlich willkommen zur Anmeldung.</h2>
          <p>
            Wir freuen uns sehr, Sie herzlichst zur unserer Hochzeit einladen zu
            dürfen.
          </p>
          <IconContext.Provider value={{ color: "#673AB7" }}>
            <a href="#App-text" className="float pulse-button">
              <i className="my-float">
                <FaArrowDown />
              </i>
            </a>
          </IconContext.Provider>
        </header>
        <div className="container dataText" id="App-text">
          <h1>Wichtigste Informationen</h1>
          <h2>Datum und Uhrzeit</h2>
          <div className="column">
            <div className="row">
              <div className="col text-left">Datum:</div>
              <div className="col text-right">{Variables.date}</div>
            </div>
            <div className="col">
              Jetzt kommts noch besser <span>😏</span>
            </div>
            <div className="row justify-content-around">
              <div className="col text-left">Uhrzeit:</div>
              <div className="col text-right">{Variables.time}</div>
            </div>
            <div className="goo">{Variables.time_hint}</div>
            <hr />
            <h2>Addressen</h2>
            <div className="row justify-content-around">
              <div className="col text-left">Standesamt:</div>
              <div className="col text-right">{Variables.standesamt}</div>
            </div>
            <div className="row justify-content-around">
              <div className="col text-left">Restaurant:</div>
              <div className="col text-right">{Variables.restaurant}</div>
            </div>
            <hr />
            <div>Bitte beachten</div>
            <div className="col">{Variables.detail1_optional}</div>
            <div className="col">{Variables.detail2_optional}</div>
            <div className="col">{Variables.detail3_optional}</div>
          </div>
          <IconContext.Provider value={{ color: "#673AB7" }}>
            <a href="#App-body" className="float pulse-button">
              <i className="my-float ">
                <FaArrowDown />
              </i>
            </a>
          </IconContext.Provider>
        </div>
        <div className="App-body" id="App-body">
          <p className="textHeader">
            Wählen Sie aus der Liste Ihren Namen, geben Sie anschließend Ihren
            Code aus der Einladung.
          </p>
          <p className="textSubHeader">
            Füllen Sie bitte kurz den folgenden Formular aus und klicken Sie
            anschließend auf ABSENDEN.
          </p>
          <Form onSubmit={this.addGast} className="dropDown">
            <Form.Group controlId="selectFamily">
              {selector}
              <div className="mt-1" />
              <Form.Control
                required={true}
                type="text"
                maxLength={4}
                name="code"
                placeholder="Ihr 4 Stelliger persönlicher Code"
                onChange={(e) => {
                  this.checkCode(e);
                }}
              />
            </Form.Group>
            {validationPassed}
          </Form>
        </div>
      </div>
    );
  }
}

export default Wedding;
